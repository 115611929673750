<template>
    <div>
        <p style="font-size: 14px;text-indent: 16px;color: #aaa;margin: 0;padding: 0;line-height: 39px;">编辑团购</p>
        <div style="box-shadow: 0 8px 12px #ebedf0;">
            <van-cell-group>
                <van-field v-model="form.shop_name" label="店铺名称" label-width="80" readonly />
                <van-field v-model="form.goods_name" label="团购名称" label-width="80" placeholder="请输入团购名称(与大众点评一致)" :disabled="in_used"/>
                <van-field v-model="form.price" label="团购价格" type="number" label-width="80" placeholder="请输入团购价格" :disabled="in_used"/>
                <van-field v-model="form.verify_img" label-width="80">
                    <template #label>
                        <div class="field_label">
                            <p>标题图片</p>
                            <p style="font-size: 12px;color:rgb(100, 101, 102)">(宽高比1:1)</p>
                            <p style="font-size: 12px;color:rgb(100, 101, 102)">(1张)</p>
                        </div>
                    </template>
                    <template slot="input" slot-scope="props">
                        <div style="display: flex;">
                            <div v-if="title_img" class="van-image van-uploader__preview-image" style="box-sizing: border-box;margin-right: 5px;">
                                <van-image :src="title_img" fit="fill" style="display: block;height: 80px;width: 80px;" @click="showDemo([title_img])"/>
                            </div>
                            <div class="van-uploader__upload" @click="chooseTitle" v-if="!in_used">
                                <i class="van-icon van-icon-photograph van-uploader__upload-icon"></i>
                                <div class="text" style="color: #646566;font-size: 12px;text-align: center;">点击上传</div>
                            </div>
                        </div>
                    </template>
                    <template #right-icon>
                        <span style="color: red;text-decoration: underline;" @click="help(['http://lf-public.oss-cn-shenzhen.aliyuncs.com/lottery/goods_title.jpg'])">示例</span>
                    </template>
                </van-field>
                <van-field label-width="80">
                    <template #label>
                        <div class="field_label">
                            <p>商品图片</p>
                            <p style="font-size: 12px;color:rgb(100, 101, 102)">(宽高比16:9)</p>
                            <p style="font-size: 12px;color:rgb(100, 101, 102)">(1-9张)</p>
                        </div>
                    </template>
                    <template slot="input" slot-scope="props">
                        <div style="display: flex;flex-wrap: wrap;">
                            <div v-for="(item,index) in goods_img" :key="index" class="van-image van-uploader__preview-image" style="box-sizing: border-box;margin:0 5px 5px 0;">
                                <van-image :src="item" fit="fill" style="display: block;height: 80px;width: 80px;" @click="showDemo(goods_img,index)"/>
                            </div>
                            <div class="van-uploader__upload" @click="chooseGoods" v-if="!in_used">
                                <i class="van-icon van-icon-photograph van-uploader__upload-icon"></i>
                                <div class="text" style="color: #646566;font-size: 12px;text-align: center;">点击上传</div>
                            </div>
                        </div>
                    </template>
                    <template #right-icon>
                        <span style="color: red;text-decoration: underline;" @click="help(['http://lf-public.oss-cn-shenzhen.aliyuncs.com/lottery/goods_detail.jpg'])">示例</span>
                    </template>
                </van-field>
                <van-field label-width="80">
                    <template #label>
                        <div class="field_label">
                            <p>团购链接</p>
                        </div>
                    </template>
                    <template #input>
                        <textarea class="myCircle_input" v-model="form.detail" ref="autosize_textarea" @input="autoTextarea" placeholder="请复制店铺团购链接并粘贴" :disabled="in_used"></textarea>
                    </template>
                    <template #right-icon>
                        <span style="color: red;text-decoration: underline;" @click="help(['http://lf-public.oss-cn-shenzhen.aliyuncs.com/upload/goods_link.jpg'])">示例</span>
                    </template>
                </van-field>
            </van-cell-group>
        </div>
        <div style="width: 100%;padding:16px;box-sizing: border-box;">
            <van-button color="linear-gradient(to right,#ff6034,#ee0a24)" style="border-radius: 22px;" block v-if="!in_used"
                        @click="submit" :loading="submitting">确定
            </van-button>
            <van-button color="linear-gradient(to right,#ff6034,#ee0a24)" style="border-radius: 22px;" block v-else
                        disabled>发布过霸王餐的团购不允许修改
            </van-button>
        </div>
    </div>
</template>

<script>
    import {ImagePreview, Notify, Toast,Dialog} from "vant";
    import wx from "weixin-js-sdk";

    export default {
        name: "GoodsCreate",
        data() {
            return {
                goods_id:0,
                form: {
                    goods_name: '',
                    price: '',
                    shop_id:'',
                    shop_name:'',
                    // title_img: '',
                    // goods_img: [],
                    detail: '',
                },
                title_img: '',
                goods_img: [],
                default_index:0,
                submitting: false,
                in_used: false,
            }
        },
        async mounted() {
            this.form.shop_id = parseInt(this.$route.query.shop_id);
            this.form.shop_name = this.$route.query.shop_name;
            // 如果传了goods_id,修改goods
            if(this.$route.query.goods_id){
                this.goods_id = parseInt(this.$route.query.goods_id);
                await this.loadGoods();
            } else {
                this.form.title_img = '';
                this.form.goods_img = [];
            }
        },
        methods: {
            // 加载goods
            loadGoods(){
                return new Promise((resolve, reject) => {
                    this.post('/api/client/shop/goods/detail',{goods_id:this.goods_id}).then(result=>{
                        if(result.code === 0){
                            this.in_used = result.data.in_used;
                            this.form.goods_name = result.data.goods_name;
                            this.form.shop_id = result.data.shop_id;
                            this.title_img = result.data.title_img;
                            this.goods_img = result.data.goods_img;
                            this.form.goods_id = result.data.goods_id;
                            this.form.price = result.data.price;
                            this.form.detail = result.data.detail;
                            this.autoTextarea();
                            resolve();
                        } else {
                            Toast('加载失败');
                            reject();
                        }
                    })
                })
            },
            showDemo(img,startPosition = 0) {
                ImagePreview({
                    images: img,
                    startPosition:startPosition,
                    closeable: true,
                    closeOnPopstate: true,
                });
            },
            chooseTitle() {
                let that = this;
                wx.chooseImage({
                    count: 1, // 默认9
                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (result) {
                        wx.uploadImage({
                            localId: result.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: function (res) {
                                // 保存服务器端ID
                                that.form.title_img = res.serverId;
                                // 预览图片
                                if (/(Android)/i.test(navigator.userAgent)) {
                                    that.title_img = result.localIds[0];
                                } else {
                                    // ios无法预览，需要base64编码
                                    wx.getLocalImgData({
                                        localId: result.localIds[0], // 图片的localID
                                        success: function (res) {
                                            that.title_img = res.localData;
                                        }
                                    });
                                }
                            },
                            fail: function (res) {
                                Toast('图片选择失败');
                                console.log(res);
                            },
                        });
                    },
                    fail: function (res) {
                        that.post('/api/common/log', {chooseImageError: res}).then(result => {
                            Toast('图片选择失败');
                            console.log(result);
                        });
                    },
                });
            },
            chooseGoods() {
                let that = this;
                wx.chooseImage({
                    count: 9, // 默认9
                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (result) {
                        that.submitting = true;
                        console.log(result);
                        that.form.goods_img = []; // 清空重新上传
                        that.goods_img = [];
                        that.uploadImage(result);
                    },
                    fail: function (res) {
                        that.post('/api/common/log', {chooseImageError: res}).then(result => {
                            Toast('图片选择失败');
                        });
                    },
                });
            },
            async uploadImage(result) {
                let that = this;
                for (let i = 0; i < result.localIds.length; i++) {
                    await that.upload(result, i);
                }
                that.submitting = false;
            },
            upload(result, i) {
                let that = this;
                return new Promise((resolve, reject) => {
                    wx.uploadImage({
                        localId: result.localIds[i], // 需要上传的图片的本地ID，由chooseImage接口获得
                        isShowProgressTips: 1, // 默认为1，显示进度提示
                        success: function (res) {
                            // 保存服务器端ID
                            that.form.goods_img.push(res.serverId);
                            console.log(that.form.goods_img);
                            // 预览图片
                            if (/(Android)/i.test(navigator.userAgent)) {
                                that.goods_img.push(result.localIds[i]);
                                resolve();
                            } else {
                                // ios无法预览，需要base64编码
                                wx.getLocalImgData({
                                    localId: result.localIds[i], // 图片的localID
                                    success: function (res) {
                                        console.log(res);
                                        that.goods_img.push(res.localData);
                                        resolve();
                                    },
                                    fail: function (res) {
                                        console.log(res);
                                        reject();
                                    },
                                });
                            }
                        },
                        fail: function (res) {
                            Notify('图片上传失败，请重试');
                            console.log(res);
                            reject();
                        },
                    });
                })
            },
            submit() {
                let that = this;
                console.log(that.form);
                let form = this.form;

                if(!form.goods_name){
                    this.$notify('请输入团购名称');
                    return;
                }
                if(!form.price){
                    this.$notify('请输入价格');
                    return;
                }
                if(!form.title_img){
                    this.$notify('请上传标题图片');
                    return;
                }
                if(!form.goods_img){
                    this.$notify('请上传商品图片');
                    return;
                }
                if(!form.detail){
                    this.$notify('请输入团购链接');
                    return;
                }
                that.submitting = true;
                this.post('/api/client/shop/goods/create', this.form).then(result => {
                    console.log(result);
                    if(result.code === 0){
                        that.submitting = false;
                        Dialog({message:result.msg}).then(()=>{ that.$router.back()});
                    } else {
                        that.submitting = false;
                        Dialog({message:result.msg});
                    }
                })
            },
            autoTextarea() {
                this.$nextTick(function () {
                    let textarea = this.$refs.autosize_textarea;
                    textarea.style.height = textarea.scrollHeight + 'px';
                })
            },
            help(urls){
                ImagePreview({
                    images: urls,
                    closeable: true,
                    closeOnPopstate: true,
                });
            }
        }
    }
</script>

<style scoped>
    .myCircle_input{
        border: none;
        outline: none;
        overflow: hidden;
        resize: none;
        word-break: break-all;
        box-sizing: border-box;
        width: 100%;
        padding: 0;
        min-height: 96px;
    }
    .field_label p{
        margin: 0;
    }
</style>